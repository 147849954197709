
function getMembersArray(){
  const groupMembers = JSON.parse(localStorage.getItem('group-members'))
  
  console.log('GET MEMBERS')
  console.log(groupMembers)

  if (groupMembers === null) {
    return [];
  }else{
    return [...new Set(groupMembers)];
  }

}

function setMembersArray(){
  const membersArray = getMembersArray()
  console.log('SET MEMBERS')
  $('.add-member-check-box').each(function(){
    const dataChecked = parseInt($(this).data("checked"))

    if( dataChecked === 1){
      const accountId = parseInt($(this).data("account-id"))
      membersArray.push(accountId)
    }
  })

  localStorage.setItem('group-members', JSON.stringify(membersArray))
}

function setInitialData(){
  localStorage.removeItem('group-members')
  $('.add-member-check-box').each(function(){
    const dataChecked = parseInt($(this).data("checked"))
    console.log(dataChecked)
    if( dataChecked === 1){
      $(this).prop('checked', true)
    }
  })

  setMembersArray()
}

function checkBoxToggler(){
  $('.add-member-check-box').click(function(){
    const dataChecked = parseInt($(this).data("checked"))
    console.log(parseInt($(this).data("checked")))
    if( dataChecked === 1){
      $(this).prop('checked', false)
      $(this).data("checked", 0)
    }else{
      $(this).prop('checked', true)
      $(this).data("checked", 1)
    }
    console.log(parseInt($(this).data("checked")))
    setMembersArray()
  })

}

function saveToBackEnd(){
  setMembersArray() //first set current status
  const communicationGroupId = $('select[name="selected-group"] option:selected').val()
  const accountIds = getMembersArray()

  console.log(accountIds)

  $.ajax({
    type: 'POST',
    url: '/addMembersToGroup',
    data: {
      communication_group: {
        id: communicationGroupId,
        account_ids: accountIds
      }
    },
    dataType: "json",
    success: function (data) {
      localStorage.removeItem('group-members')
      $('#addToGroup').modal('hide')

      window.location.replace('/admin/communication_groups')

      Swal.fire(
        'Successfully added!',
        'Member Added to Group!',
        'success'
      )

      console.log(data);
    },
    error: function (data) {
      console.log('An error occurred.');
      console.log(data);
    },
  });
}

$(document).ready(function () {



  if ($('#add-members-table').length > 0) {
    setInitialData()
    checkBoxToggler()

    $('#add-members-table').DataTable({
      "columnDefs": [
        { "searchable": false, "targets": 2 }
      ]
    });

    $('.add-to-group-btn').click(function(e){
      saveToBackEnd()
    })

  }
})