function getMembersArray() {
  const groupMembers = JSON.parse(localStorage.getItem('group-members'))

  if (groupMembers === null) {
    return [];
  } else {
    return [...new Set(groupMembers)];
  }

}
function addMemberToGroup() {
  const communicationGroupId = $('select[name="selected-group"] option:selected').val()

  const accountIds = getMembersArray()

  $.ajax({
    type: 'POST',
    url: '/addMembersToGroup',
    data: {
      communication_group: {
        id: communicationGroupId,
        account_ids: accountIds
      }
    },
    dataType: "json",
    success: function (data) {
      $('#addToGroup').modal('hide')

      Swal.fire(
        'Successfully added!',
        'Member Added to Group!',
        'success'
      )

      localStorage.removeItem('group-members')

      console.log(data);

    },
    error: function (data) {
      console.log('An error occurred.');
      console.log(data);
    },
  });
}

$(document).ready(function () {

  $('#members-table').DataTable({
    ajax: '/admin/accounts',
    columns: [
      { data: 'name' },
      { data: 'email' },
      { data: 'membership_status' },
      { data: 'registration_number' },
      {
        data: 'profile_id',
        className: 'dt-center editor-delete',
        defaultContent: "<a href=''>Edit</a>",
        orderable: false
      }
    ],
    "columnDefs": [
      { "targets": 0, orderable: true },
      {
        "targets": 4,
        // data: 'profile_id',
        "searchable": false,
        render: function (data, type, row, meta) {
          return `<a href="/admin/accounts/${data}/edit">Edit</a>`;
        }
      }
    ]
  });

  if ($('#members-table').length > 0) {
    $('.add-to-group-link').click(function (e) {
      const membersArray = getMembersArray()
      const accountId = e.target.getAttribute("data-account-id")
      $('#account-id').val(accountId)
      membersArray.push(accountId)
      localStorage.setItem('group-members', JSON.stringify(membersArray))
    })

    $('#add-to-group-btn').click(function () {
      addMemberToGroup()
    })
  }
})